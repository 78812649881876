//------------------------------------------------------------------------------------

import CryptoJS from "crypto-js";

//------------------------------------------------------------------------------------

const secretPass = "mypassword";

export const encryptData = (data) => {
    let text = JSON.stringify(data);
    let encryptedDataText = CryptoJS.AES.encrypt(
        text,
        secretPass
    ).toString();

    return encryptedDataText;
};

export const decryptData = (encryptedDataText) => {
    const bytes = CryptoJS.AES.decrypt(encryptedDataText, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return data;
};

//------------------------------------------------------------------------------------
