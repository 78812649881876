//------------------------------------------------------------------------------------

import '../internal.scss';
import './Profile.scss';

import baseUrl, { adminBaseUrl } from '../../../scripts/baseUrl';
import { useEffect, useState } from 'react';

import Banner from '../../../components/Banner/Banner';
import Buffer from '../../../components/Buffer/Buffer';
import { Edit } from '../../../components/Icons/Icons';
import Fallback from '../../../components/Fallback/Fallback';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';
import Navigation from '../../../components/Navigation/Navigation';
import auth from '../../../scripts/auth';
import axios from 'axios';
import calculateDateStatus from '../../../scripts/calculateDateStatus';
import unauthorizedRedirection from '../../../scripts/unauthorizedRedirection';
import { socket } from '../../../App';

//------------------------------------------------------------------------------------

const Profile = () => {
	unauthorizedRedirection();
	document.title = `My profile • Bhusawal Division Catering Vendor Id`;

	const [owner, setOwner] = useState({
		photo: '',
		idPhoto: '',
		shopPhoto: '',
		signPhoto: '',
		stampPhoto: '',
		fullname: '',
		shopAddress: '',
		shopName: '',
		username: '',
		email: '',
		mobile: '',
		startingDate: '',
		endingDate: '',
		password: '',
	});
	const [notFound, setNotFound] = useState(true);
	const [buffer, setBuffer] = useState(true);

	useEffect(() => {
		getProfile();

		socket.on('isOwnerUpdated', (data) => {
			getProfile();
		});
	}, []);

	let accessToken = JSON.parse(localStorage.getItem('owner')).accessToken;

	const getProfile = async () => {
		const owner = JSON.parse(localStorage.getItem('owner'));

		try {
			const res = await axios.get(`${baseUrl}/owners/${owner.id}`, {
				headers: {
					'access-token': accessToken,
				},
			});

			if (!auth(res.data.message)) return;

			if (res.data) {
				setOwner(res.data);
				setNotFound(false);

				if (res.data.isDeleted === 1 || res.data.isDisabled === 1) {
					localStorage.setItem('owner', '');
					window.location.replace('/');
				}
			} else {
				setNotFound(true);
			}
			setBuffer(false);
		} catch (err) {
			console.log(err);
		}
	};

	const getRemainingDays = (endingDate) => {
		const date1 = new Date();
		const date2 = new Date(endingDate);
		const diffTime = Math.abs(date2 - date1);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

		if ((date2 - date1).toString()[0] == '-') {
			return {
				className: 'warning-container strong',
				label: `Owner's validity expired ${
					diffDays - 1
				} days ago. Please renew owner.`,
			};
		}

		if (diffDays < 1) {
			return {
				className: 'warning-container strong',
				label: `Owner's validity expired. Please renew owner.`,
			};
		}

		if (diffDays < 15) {
			return {
				className: 'warning-container strong',
				label: `${diffDays} days remained. Please renew owner.`,
			};
		}

		return {
			className: 'warning-container',
			label: `${diffDays} days remaining.`,
		};
	};

	const updateStallLocation = async (ownerId, shopAddress) => {
		let value = window.prompt('Update stall location', shopAddress);

		if (value) {
			const data = {
				id: ownerId,
				shopAddress: value,
			};
			try {
				setNotFound(true);
				setBuffer(true);
				const res = await axios.put(
					`${adminBaseUrl}/owners/updateShopAddress`,
					data,
					{
						headers: {
							'access-token': accessToken,
						},
					}
				);

				if (!auth(res.data.message)) return;

				if (res.data) {
					getProfile();

					socket.emit('updateOwner', {
						message: 'updateOwner',
					});
				} else {
					setNotFound(true);
				}
				setBuffer(false);
			} catch (err) {
				console.log(err);
			}
		}
	};

	return (
		<>
			<Header />
			<div className='screen-container'>
				<Banner />
				<div className='side-container'>
					<Navigation />
					<div className='side-content'>
						{buffer && notFound ? <Buffer /> : <></>}

						{owner.id == '' && !buffer ? (
							<Fallback
								title='Not found'
								text='No data found in the database. Try refreshing the page.'
							/>
						) : (
							<></>
						)}

						{owner.id != '' && !notFound && !buffer ? (
							<>
								<div className='greeting-sub-title title-medium'>
									Welcome,
								</div>
								<div className='greeting-title headline-large'>
									{owner.fullname}
								</div>

								{owner.isVendorsRenewed == 0 &&
									new Date() >
										new Date(
											`${new Date().getFullYear()}-02-01`
										) &&
									new Date() <
										new Date(
											`${new Date().getFullYear()}-04-01`
										) && (
										<div className='renew-vendors'>
											<div className='headline-medium'>
												Renew vendors.
											</div>
											<br />
											Validity of current vendors will
											expire on 31-03-
											{new Date().getFullYear()}
										</div>
									)}

								<div className='container-1'>
									<div className='profile-image'>
										<div className='label label-medium'>
											Passport photo
										</div>
										<img
											className='img'
											src={owner.photo}
											alt=''
										/>
									</div>
									<div className='id-image'>
										<div className='label label-medium'>
											Id proof
										</div>

										{owner.idPhoto
											.split('.')
											.reverse()[0] === 'pdf' ? (
											<embed
												src={owner.idPhoto}
												className='img'
											/>
										) : (
											<img
												className='img'
												src={owner.idPhoto}
												alt=''
											/>
										)}
									</div>
								</div>

								<div className='container-2'>
									<div className='shop-image'>
										<div className='label label-medium'>
											Stall photo
										</div>
										<img
											className='img'
											src={owner.shopPhoto}
											alt=''
										/>
									</div>
									<div className='sign-image'>
										<div className='label label-medium'>
											Signature
										</div>
										<img
											className='img'
											src={owner.signPhoto}
											alt=''
										/>
									</div>
									<div className='stamp-image'>
										<div className='label label-medium'>
											Stamp
										</div>
										<img
											className='img'
											src={owner.stampPhoto}
											alt=''
										/>
									</div>
								</div>

								{/* <div className='warning-container body-large'>Your validity is upto {owner.endingDate}.</div> */}

								{owner.reason == null && (
									<div
										className={
											calculateDateStatus(
												owner.endingDate
											).className
										}
									>
										{
											calculateDateStatus(
												owner.endingDate
											).message
										}
									</div>
								)}

								{/* <div className='label label-medium'>&nbsp;</div> */}

								<div className='container-3'>
									<div className='label label-medium'>
										Contractor fullname
									</div>
									<div className='full-name title-large'>
										{owner.fullname}
									</div>

									<div className='label label-medium'>
										Stall location{' '}
										<button
											className='icon-button'
											style={{ display: 'inline-grid' }}
											onClick={() =>
												updateStallLocation(
													owner.id,
													owner.shopAddress
												)
											}
										>
											<Edit />
										</button>
									</div>

									<div className='id-number body-large'>
										{owner.shopAddress}
									</div>

									<div className='label label-medium'>
										Station
									</div>
									<div className='id-number body-large'>
										{owner.station ? owner.station : '--'}
									</div>

									<div className='label label-medium'>
										Stall type
									</div>
									<div className='id-number body-large'>
										{owner.stallType
											? owner.stallType
											: '--'}
									</div>

									<div className='details-container-1'>
										<div>
											<div className='label label-medium'>
												Stall name
											</div>
											<div className='aadhaar-id body-large'>
												{owner.shopName}
											</div>
										</div>

										<div>
											<div className='label label-medium'>
												Starting date
											</div>
											<div className='valid-upto-date body-large'>
												{owner.startingDate != null
													? `${new Date(
															owner.startingDate
													  ).getDate()}-${
															new Date(
																owner.startingDate
															).getMonth() + 1
													  }-${new Date(
															owner.startingDate
													  ).getFullYear()}`
													: 'Not specified'}
											</div>
										</div>

										<div>
											<div className='label label-medium'>
												Ending date
											</div>
											<div className='valid-for body-large'>
												{owner.endingDate != null
													? `${new Date(
															owner.endingDate
													  ).getDate()}-${
															new Date(
																owner.endingDate
															).getMonth() + 1
													  }-${new Date(
															owner.endingDate
													  ).getFullYear()}`
													: 'Not specified'}
											</div>
										</div>
									</div>

									<div className='details-container-1'>
										<div>
											<div className='label label-medium'>
												Email id
											</div>
											<div className='unit body-large'>
												{owner.email}
											</div>
										</div>

										<div>
											<div className='label label-medium'>
												Mobile no.
											</div>
											<div className='issue-date body-large'>
												{owner.mobile}
											</div>
										</div>

										{/* <div>
                                                <div className='label label-medium'>Username</div>
                                                <div className='designation body-large'>{owner.username}</div>
                                            </div> */}
									</div>

									{owner.reason != null ? (
										<div>
											<div className='label label-medium'>
												Reason for not having validity
											</div>
											<div className='unit body-large'>
												{owner.reason}
											</div>
										</div>
									) : (
										<></>
									)}

									<div className='details-container-1'>
										<div>
											<div className='label label-medium'>
												Number of stalls
											</div>
											<div className='unit body-large'>
												{owner.numberOfStall}
											</div>
										</div>
										<div>
											<div className='label label-medium'>
												Number of trolleys
											</div>
											<div className='unit body-large'>
												{owner.numberOfTrolley}
											</div>
										</div>
										<div></div>
									</div>

									<div className='details-container-1'>
										<div>
											<div className='label label-medium'>
												Number of staff
											</div>
											<div className='unit body-large'>
												{owner.stallTrolleyVendorCount}
											</div>
										</div>
										<div>
											<div className='label label-medium'>
												PF vendor
											</div>
											<div className='unit body-large'>
												{owner.pfVendorCount}
											</div>
										</div>
										<div>
											<div className='label label-medium'>
												Supporting staff
											</div>
											<div className='unit body-large'>
												{owner.supportingStaff}
											</div>
										</div>
									</div>

									{/* <div>
                                            <div className='label label-medium'>Total number of stall/trolley vendors you can add</div>
                                            <div className='unit body-large'>Total stall/trolley vendors: {owner.stallTrolleyVendorCount}</div>
                                        </div>

                                        <div>
                                            <div className='label label-medium'>Total number of PF vendors you can add</div>
                                            <div className='issue-date body-large'>Total PF vendors: {owner.pfVendorCount}</div>
                                        </div> */}
								</div>
							</>
						) : (
							<></>
						)}

						<Footer />
					</div>
				</div>
			</div>
		</>
	);
};

export default Profile;

//------------------------------------------------------------------------------------
