//------------------------------------------------------------------------------------

import axios from 'axios';
import baseUrl from './baseUrl';

//------------------------------------------------------------------------------------

const checkLoginStatus = async () => {
    if (localStorage.getItem('owner') === null) {
        localStorage.setItem('owner', '');
    }

    if (
        localStorage.getItem('owner') === null ||
        localStorage.getItem('owner') === '' ||
        localStorage.getItem('owner') === undefined
    ) return;

    let owner = JSON.parse(localStorage.getItem('owner'));

    try {
        const res = await axios.get(`${baseUrl}/login/status/${owner.id}`);
        if (res.data) {
            return true;
        } else {
            return false;
        }
    } catch (err) {
        console.log(err);
    }
}

export default checkLoginStatus;

//------------------------------------------------------------------------------------

