//------------------------------------------------------------------------------------

import './Loading.scss';

//------------------------------------------------------------------------------------

const Loading = () => {
	return (
		<div className='loading-screen'>
			<div className='laoder-container'>
				<div className='loader'>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<div className='text body-large'>Loading...</div>
			</div>
		</div>
	);
};

export default Loading;

//------------------------------------------------------------------------------------
