//------------------------------------------------------------------------------------

import './Navigation.scss';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu, People, Person, PersonAdd } from '../Icons/Icons';

import Loading from '../Loading/Loading';
import auth from '../../scripts/auth';
import axios from 'axios';
import baseUrl from '../../scripts/baseUrl';
import { useState } from 'react';

//------------------------------------------------------------------------------------

const buttons = [
	{
		label: 'My profile',
		icon: <Person />,
		link: '/home/profile',
		isActive: false,
	},
	{
		label: 'Add vendor',
		icon: <PersonAdd />,
		link: '/register/vendor',
		isActive: false,
	},
	{
		label: 'View vendors',
		icon: <People />,
		link: '/home/vendors',
		isActive: false,
	},
];

const Navigation = () => {
	const [navigationState, setNavigationState] = useState(false);
	const currentScreen = useLocation().pathname;
	const navigate = useNavigate();
	const [loader, setLoader] = useState(false);

	for (const button of buttons) {
		if (currentScreen === button.link) {
			button.isActive = true;
		} else {
			button.isActive = false;
		}
	}

	const handleNavigationState = () => {
		setNavigationState((prev) => !prev);
	};

	const checkValidVendorsCount = async () => {
		const owner = JSON.parse(localStorage.getItem('owner'));
		let accessToken = JSON.parse(localStorage.getItem('owner')).accessToken;

		try {
			setLoader(true);
			const res = await axios.get(
				`${baseUrl}/owners/vendorAddStatus/${owner.id}`,
				{
					headers: {
						'access-token': accessToken,
					},
				}
			);

			if (!auth(res.data.message)) return;

			if (res.data) {
				if (
					res.data.total.stallTrolleyVendorCount >
						res.data.added.stallTrolleyVendorCount ||
					res.data.total.pfVendorCount > res.data.added.pfVendorCount ||
					res.data.total.supportingStaff > res.data.added.supportingStaff
				) {
					const addVendor = window.confirm(
						`You can add ${
							res.data.total.stallTrolleyVendorCount -
							res.data.added.stallTrolleyVendorCount
						} staff, ${
							res.data.total.pfVendorCount - res.data.added.pfVendorCount
						} PF vendors and ${
							res.data.total.supportingStaff - res.data.added.supportingStaff
						} supporting staff.`
					);
					if (addVendor) {
						navigate('/register/vendor');
					}
				} else {
					alert(
						`You cannot add more than ${
							res.data.total.stallTrolleyVendorCount +
							res.data.total.pfVendorCount +
							res.data.total.supportingStaff
						} vendors.`
					);
				}
			} else {
				alert('You cannot add vendor.');
			}
		} catch (err) {
			console.log(err);
		}
		setLoader(false);
	};

	/* 
    <Link to={button.link} key={index}>
        <button className={className}>{button.label}</button>
    </Link>
    */

	return (
		<>
			{window.innerWidth > 600 || navigationState ? (
				<>
					{window.innerWidth < 600 && (
						<div
							className='side-navigation-elevation'
							onClick={handleNavigationState}
						></div>
					)}
					<div className='side-navigation'>
						{buttons.map((button, index) => {
							const className = button.isActive
								? 'navigation-button active'
								: 'navigation-button';
							return (
								<button
									key={index}
									onClick={() => {
										if (button.link == '/register/vendor') {
											checkValidVendorsCount();
										} else {
											navigate(button.link);
										}
									}}
									className={className}
								>
									{button.icon}
									{button.label}
								</button>
							);
						})}
						{/* <a
							href="http://msofts.in/"
							className="development label-small"
							target="_blank"
						>
							Designed & developed By <br />
							M-SOFTS DEVELOPMENT, Bhusawal
						</a> */}
					</div>
				</>
			) : (
				<></>
			)}
			{window.innerWidth < 600 && (
				<>
					<div className='side-navigation-handler'>
						<button
							className='icon-button'
							onClick={handleNavigationState}
						>
							<Menu />
						</button>
						<div className='title title-large'></div>
					</div>
				</>
			)}

			{loader && <Loading />}
		</>
	);
};

export default Navigation;

//------------------------------------------------------------------------------------
