//------------------------------------------------------------------------------------

import './NotFound.scss';

import { useNavigate } from 'react-router-dom';

//------------------------------------------------------------------------------------

const NotFound = () => {
	const navigate = useNavigate();
	document.title = `Not found • Bhusawal Division Catering Vendor Id`;

	return (
		<div className='not-found-screen'>
			<div className='not-found-container'>
				<div className='title display-medium'>Page not found</div>
				<div className='text body-large'>
					Please check if url in addressbar is correct or not.
				</div>
				<button
					className='outlined-button'
					onClick={() => {
						navigate('/home/profile', { replace: true });
					}}
				>
					Home
				</button>
			</div>
		</div>
	);
};

export default NotFound;

//------------------------------------------------------------------------------------
