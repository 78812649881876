import { Clear } from '../Icons/Icons';
import './ProfileAvatar.scss';
import { useEffect, useState } from 'react';
import baseUrl from '../../scripts/baseUrl';
import auth from '../../scripts/auth';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import theme from '../../scripts/theme';
import socket from '../../App';

const ProfileAvatar = () => {
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [owner, setOwner] = useState({});
	const navigate = useNavigate();

	const getProfile = async () => {
		const owner = JSON.parse(localStorage.getItem('owner'));
		let accessToken = JSON.parse(localStorage.getItem('owner')).accessToken;

		try {
			const res = await axios.get(`${baseUrl}/owners/${owner.id}`, {
				headers: {
					'access-token': accessToken,
				},
			});

			if (!auth(res.data.message)) return;

			if (res.data) {
				setOwner(res.data);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const logout = async () => {
		// if (window.confirm("Are you sure you want to logout?")) {
		const owner = JSON.parse(localStorage.getItem('owner'));

		try {
			await axios.get(`${baseUrl}/logout/${owner.id}`);
		} catch (err) {
			console.log(err);
		}
		localStorage.clear();
		navigate('/home', { replace: true });
		theme.set();

		socket.emit('updateOwner', {
			message: 'updateOwner',
		});
		// }
	};

	useEffect(() => {
		getProfile();
	}, []);

	return (
		<>
			<button
				className='avatar-button'
				onClick={() => {
					setIsDialogOpen(true);
				}}
			>
				<img src={owner.photo} />
			</button>
			{isDialogOpen && (
				<div className='avatar-dialog-container'>
					<div className='avatar-dialog'>
						<button
							className='icon-button close-button'
							onClick={() => {
								setIsDialogOpen(false);
							}}
						>
							<Clear />
						</button>
						<div className='top'>
							<div className='avatar'>
								<img src={owner.photo} />
							</div>
							<div className='name title-large'>
								{owner.fullname}
							</div>
						</div>
						<div className='foot'>
							<button
								className='filled-tonal-button'
								onClick={() => {
									navigate('/home/profile');
									setIsDialogOpen(false);
								}}
							>
								My profile
							</button>
							<button
								className='outlined-button'
								onClick={logout}
							>
								Logout
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ProfileAvatar;
