//------------------------------------------------------------------------------------

const hightlight = (searchTextField) => {
	setTimeout(() => {
		let textToSearch = searchTextField;
		let td = document.getElementsByTagName('td');

		for (let i = 0; i < td.length; i++) {
			if (td[i].classList.contains('hightlight')) {
				textToSearch = textToSearch.replace(
					/[.*+?^${}()|[\]\\]/g,
					'\\$&'
				);
				let pattern = new RegExp(`${textToSearch}`, 'gi');

				td[i].innerHTML = td[i].textContent.replace(
					pattern,
					(match) => `<mark>${match}</mark>`
				);
			}
		}
	}, 500);
};

export default hightlight;

//------------------------------------------------------------------------------------
