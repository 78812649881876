//------------------------------------------------------------------------------------

const baseUrl = 'https://user.backend.bslcatgvid.in';
export const adminBaseUrl = 'https://admin.backend.bslcatgvid.in';

// const baseUrl = 'http://localhost:1000';
// export const adminBaseUrl = 'http://localhost:1001';

export default baseUrl;

//------------------------------------------------------------------------------------
