//------------------------------------------------------------------------------------

import './Buffer.scss';

//------------------------------------------------------------------------------------

const Buffer = () => {
    return (
        <div className='buffer-container'>
            <div className="buffer">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Buffer;

//------------------------------------------------------------------------------------
