//------------------------------------------------------------------------------------

import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ExpandMore, ExpandLess, LightMode, DarkMode } from '../Icons/Icons';
import './Header.scss';
import axios from 'axios';
import baseUrl from '../../scripts/baseUrl';
import Loading from '../Loading/Loading';
import Logo from '../../assets/logo.png';
import theme from '../../scripts/theme';
import ProfileAvatar from '../ProfileAvatar/ProfileAvatar';

//------------------------------------------------------------------------------------

const buttons = [
	/* {
        label: 'Signup',
        link: '/register/owner',
        isActive: false,
    },
    {
        label: 'Signin',
        link: '/register/vendor',
        isActive: false,
    }, */
];

const SideButtons = () => {
	const [loader, setLoader] = useState(false);

	const currentScreen = useLocation().pathname;
	const navigate = useNavigate();

	// const logout = async () => {
	//     if (window.confirm("Are you sure you want to logout?")) {
	//         const owner = JSON.parse(localStorage.getItem('owner'));

	//         try {
	//             setLoader(true);
	//             const res = await axios.get(`${baseUrl}/logout/${owner.id}`);
	//             if (res.data) {
	//                 localStorage.clear();
	//                 navigate('/home', { replace: true });
	//                 theme.set();
	//                 // localStorage.setItem('owner', '');
	//             }
	//         } catch (err) {
	//             console.log(err);
	//         }
	//         setLoader(false);
	//     }
	// }

	return (
		<>
			{currentScreen == '/home' || currentScreen == '/' ? (
				<>
					<Link to='/login'>
						<button className='outlined-button'>Signin</button>
					</Link>
					<Link to='/register/owner'>
						<button className='filled-tonal-button'>Signup</button>
					</Link>
				</>
			) : (
				<>
					{/* <ProfileAvatar /> */}
					{/* <button className="outlined-button" onClick={logout}>Logout</button> */}
				</>
			)}
		</>
	);
};

const Header = () => {
	const [navigationState, setNavigationState] = useState(false);
	const [currentTheme, setCurrentTheme] = useState(
		localStorage.getItem('theme')
	);
	const currentScreen = useLocation().pathname;

	for (const button of buttons) {
		if (currentScreen === button.link) {
			button.isActive = true;
		} else {
			button.isActive = false;
		}
	}

	const handleNavigationState = () => {
		setNavigationState((prev) => !prev);
	};

	const toggleTheme = () => {
		theme.toggle();
		setCurrentTheme(localStorage.getItem('theme'));
	};

	return (
		<div className='header'>
			<div className='leading-container'>
				<div className='app-logo'>
					<img src={Logo} />
				</div>
				<div className='app-title'>
					<h1 className='title-medium'>User Panel</h1>
					{window.innerWidth > 600 && (
						<h2 className='body-small'>
							Bhusawal Division Catering Vendor Id
						</h2>
					)}
				</div>
			</div>
			<div className='trailing-container'>
				<button
					className='icon-button'
					onClick={toggleTheme}
				>
					{currentTheme == 'light' ? <DarkMode /> : <LightMode />}
				</button>

				{window.innerWidth > 600 && (
					<>
						{buttons.map((button, index) => {
							const className = button.isActive
								? 'navigation-button active'
								: 'navigation-button';
							return (
								<Link
									to={button.link}
									key={index}
								>
									<button className={className}>
										{button.label}
									</button>
								</Link>
							);
						})}
						{/* {
                            currentScreen == '/home'
                                ? <>
                                    <button className="text-button">Signin</button>
                                    <button className="outlined-button">Signup</button>
                                </>
                                : <button className="outlined-button" onClick={logout}>Logout</button>
                        } */}
						<SideButtons />
					</>
				)}
				{currentScreen == '/home' || currentScreen == '/' ? (
					<>
						{window.innerWidth < 600 && (
							<button
								className='icon-button'
								onClick={handleNavigationState}
							>
								{navigationState ? (
									<ExpandLess />
								) : (
									<ExpandMore />
								)}
							</button>
						)}
					</>
				) : (
					<ProfileAvatar />
				)}
			</div>
			{navigationState & (window.innerWidth < 600) ? (
				<div className='navigation-container'>
					{buttons.map((button, index) => {
						const className = button.isActive
							? 'navigation-button active'
							: 'navigation-button';
						return (
							<Link
								to={button.link}
								key={index}
							>
								<button className={className}>
									{button.label}
								</button>
							</Link>
						);
					})}
					<div className='bottom-buttons'>
						<SideButtons />
					</div>
				</div>
			) : (
				<></>
			)}
		</div>
	);
};

export default Header;

//------------------------------------------------------------------------------------
